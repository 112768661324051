@tailwind base;
@tailwind components;
@tailwind utilities;

.overflow-x-scroll::-webkit-scrollbar{
  height: 8px;
}

.overflow-x-scroll::-webkit-scrollbar-track {
  background: hsl(var(--background));
  border-radius: 5px;
}

.overflow-x-scroll::-webkit-scrollbar-thumb {
  background-color: hsl(var(--muted-foreground));
  border-radius: 14px;
  border: 3px solid hsl(var(--muted-foreground));
}

@font-face {
  font-family: "Satoshi";
  src: local("Satoshi"),
   url("../fonts/Satoshi-Variable.ttf") format("truetype");
  font-weight: 500;
 }
 

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 47.4% 11.2%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 47.4% 11.2%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;

    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 100% 50%;
    --destructive-foreground: 210 40% 98%;

    --ring: 215 20.2% 65.1%;

    --radius: 0.5rem;
  }

  .dark {
    --background: 224 71% 4%;
    --foreground: 213 31% 91%;

    --muted: 223 47% 11%;
    --muted-foreground: 215.4 16.3% 56.9%;

    --popover: 224 71% 4%;
    --popover-foreground: 215 20.2% 65.1%;

    --card: 224 71% 4%;
    --card-foreground: 213 31% 91%;

    --border: 216 34% 17%;
    --input: 216 34% 17%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 1.2%;

    --secondary: 222.2 47.4% 11.2%;
    --secondary-foreground: 210 40% 98%;

    --accent: 216 34% 17%;
    --accent-foreground: 210 40% 98%;

    --destructive: 0 63% 31%;
    --destructive-foreground: 210 40% 98%;

    --ring: 216 34% 17%;

    --radius: 0.5rem;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
    font-feature-settings: "rlig" 1, "calt" 1;
  }
}

:root{
  --swiper-theme-color: #4361ee !important;
}

body {
  margin: 0;
  font-family: 'Satoshi', -apple-system, 'Helvetica Neue', sans-serif;
  color: #121127;
  background-color: #ffffff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

p {
  font-family: 'Satoshi';
  margin: 0;
}

a {
  text-decoration: none;
  color: #0e153a;
}

.mapbox-popup-custom {
  font-family: 'Poppins', -apple-system, 'Helvetica Neue', sans-serif;
}

.mapbox-popup-custom strong {
  color: #4361ee;
  font-size: 1rem;
}

.mapbox-popup-custom img {
  width: 100%;
  margin-top: 0.5rem;
  border-radius: 0.5rem;
}

@-webkit-keyframes toggleOnCheckbox {
  0% {
    opacity: 0;
    transform: translate(-50%, -85%) scale(0) rotate(45deg);
  }
  70% {
    opacity: 1;
    transform: translate(-50%, -85%) scale(0.9) rotate(45deg);
  }
  100% {
    transform: translate(-50%, -85%) scale(0.8) rotate(45deg);
  }
}
@keyframes toggleOnCheckbox {
  0% {
    opacity: 0;
    transform: translate(-50%, -85%) scale(0) rotate(45deg);
  }
  70% {
    opacity: 1;
    transform: translate(-50%, -85%) scale(0.9) rotate(45deg);
  }
  100% {
    transform: translate(-50%, -85%) scale(0.8) rotate(45deg);
  }
}
@-webkit-keyframes toggleOnRadio {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(0);
  }
  70% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(0.9);
  }
  100% {
    transform: translate(-50%, -50%) scale(0.8);
  }
}
@keyframes toggleOnRadio {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(0);
  }
  70% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(0.9);
  }
  100% {
    transform: translate(-50%, -50%) scale(0.8);
  }
}
